import React, { useState } from 'react';
import { 
  Calendar, 
  Card, 
  Select, 
  Badge, 
  Typography, 
  Row, 
  Col, 
  Statistic, 
  Modal, 
  Tag,
  Empty,
  Button,
  Timeline,
  Tooltip
} from 'antd';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  TeamOutlined,
  EuroOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/fr';

// Initialize dayjs plugins
dayjs.extend(isBetween);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('fr');

const { Title, Text } = Typography;
const { Option } = Select;

export const ExperienceAvailabilityViewer = ({ experiences = [], onSelectTimeSlot }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [availabilityModalVisible, setAvailabilityModalVisible] = useState(false);
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [viewMode, setViewMode] = useState('month');

  // Get all available time slots for a specific date
  const getDateAvailability = (date) => {
    const slots = experiences.reduce((acc, exp) => {
      const recurences = exp.recurenceDates || [];
      
      recurences.forEach(rec => {
        const startDate = dayjs(rec.dateDebut);
        const endDate = dayjs(rec.dateFin);
        const currentDay = dayjs(date).format('dddd');
        
        if (date.isBetween(startDate, endDate, 'day', '[]') && 
            rec.jour === currentDay) {
          acc.push({
            ...exp,
            startTime: dayjs(`${date.format('YYYY-MM-DD')} ${rec.heurDebut}`),
            endTime: dayjs(`${date.format('YYYY-MM-DD')} ${rec.heurFin}`),
            recurence: rec
          });
        }
      });
      
      return acc;
    }, []);

    return slots.sort((a, b) => a.startTime.diff(b.startTime));
  };

  // Calculate monthly statistics
  const getMonthlyStats = (date) => {
    const startOfMonth = date.startOf('month');
    const endOfMonth = date.endOf('month');
    let totalSlots = 0;
    let uniqueExperiences = new Set();
    let totalCapacity = 0;

    let currentDay = startOfMonth;
    while (currentDay.isBefore(endOfMonth) || currentDay.isSame(endOfMonth, 'day')) {
      const slots = getDateAvailability(currentDay);
      totalSlots += slots.length;
      slots.forEach(slot => {
        uniqueExperiences.add(slot.id);
        totalCapacity += slot.nombreMaxInvite;
      });
      currentDay = currentDay.add(1, 'day');
    }

    return {
      totalSlots,
      uniqueExperiences: uniqueExperiences.size,
      totalCapacity
    };
  };

  // Calendar cell renderer
  const dateCellRender = (date) => {
    const slots = getDateAvailability(date);
    
    if (slots.length === 0) return null;

    return (
      <div className="h-full">
        {slots.slice(0, 3).map((slot, index) => (
          <Badge
            key={`${slot.id}-${index}`}
            status={slot.isVerified ? "success" : "warning"}
            text={
              <Tooltip title={`${slot.titre} - ${slot.startTime.format('HH:mm')} - ${slot.endTime.format('HH:mm')}`}>
                <span className="text-xs truncate block">
                  {slot.startTime.format('HH:mm')} - {slot.titre.substring(0, 15)}...
                </span>
              </Tooltip>
            }
          />
        ))}
        {slots.length > 3 && (
          <Text type="secondary" className="text-xs">
            +{slots.length - 3} plus...
          </Text>
        )}
      </div>
    );
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    const slots = getDateAvailability(date);
    setSelectedDateEvents(slots);
    setAvailabilityModalVisible(true);
  };

  const stats = getMonthlyStats(selectedDate);

  return (
    <div className="p-6">

      <Row gutter={[16, 16]}>
        <Col xs={24} lg={18}>
          <Card>
            <Calendar
              onSelect={handleDateSelect}
              dateCellRender={dateCellRender}
              mode={viewMode}
              onPanelChange={(date, mode) => {
                setSelectedDate(date);
                setViewMode(mode);
              }}
            />
          </Card>
        </Col>
        
        <Col xs={24} lg={6}>
          <Card title="Filtres">
            <Select
              style={{ width: '100%' }}
              placeholder="Type d'affichage"
              className="mb-4"
              value={viewMode}
              onChange={setViewMode}
            >
              <Option value="month">Vue par mois</Option>
              <Option value="week">Vue par semaine</Option>
            </Select>
            
            <Select
              style={{ width: '100%' }}
              placeholder="Filtrer par expérience"
              onChange={setSelectedExperience}
              allowClear
            >
              {experiences.map(exp => (
                <Option key={exp.id} value={exp.id}>
                  {exp.titre}
                </Option>
              ))}
            </Select>
          </Card>
        </Col>
      </Row>

      <Modal
        title={
          <div className="flex items-center">
            <CalendarOutlined className="mr-2" />
            Disponibilités du {selectedDate.format('DD MMMM YYYY')}
          </div>
        }
        open={availabilityModalVisible}
        onCancel={() => setAvailabilityModalVisible(false)}
        footer={null}
        width={800}
      >
        {selectedDateEvents.length === 0 ? (
          <Empty description="Aucune disponibilité pour cette date" />
        ) : (
          <Timeline mode="left">
            {selectedDateEvents.map((event, index) => (
              <Timeline.Item
                key={`${event.id}-${index}`}
                color={event.isVerified ? 'green' : 'orange'}
                label={`${event.startTime.format('HH:mm')} - ${event.endTime.format('HH:mm')}`}
              >
                <Card size="small">
                  <Title level={5}>{event.titre}</Title>
                  <Row gutter={[16, 8]}>
                    <Col span={12}>
                      <Text type="secondary">
                        <TeamOutlined className="mr-1" />
                        Capacité: {event.nombreMaxInvite} personnes
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Text type="secondary">
                        <EuroOutlined className="mr-1" />
                        Prix: {event.prixParInvite}€ par personne
                      </Text>
                    </Col>
                  </Row>
                  <div className="mt-2">
                    <Tag color={event.isVerified ? 'success' : 'warning'}>
                      {event.isVerified ? 'Vérifié' : 'En attente de vérification'}
                    </Tag>
                    {event.recurence && (
                      <Tag color="blue">
                        Récurrent: {event.recurence.jour}
                      </Tag>
                    )}
                  </div>
                  <Button 
                    type="primary" 
                    size="small" 
                    className="mt-2"
                    onClick={() => onSelectTimeSlot && onSelectTimeSlot(event)}
                  >
                    Voir les détails
                  </Button>
                </Card>
              </Timeline.Item>
            ))}
          </Timeline>
        )}
      </Modal>
    </div>
  );
};

export default ExperienceAvailabilityViewer;