import React, { useState } from 'react';
import { Rate, Input, Button, Modal, Form, message } from 'antd';
import { StarOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
const ReviewModal = ({ reservation, visible, onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const { keycloak } = useKeycloak();
  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const values = await form.validateFields();
      
      const response = await axios.post(
        `https://fnfapi.online/api/reviews/add`,
        null,
        {
          params: {
            experienceId: reservation.experienceCulinaire.id,
            userId: reservation.user.id,
            cuisineRating: values.cuisineRating,
            ambianceRating: values.ambianceRating,
            valueRating: values.valueRating,
            cleanlinessRating: values.cleanlinessRating,
            comment: values.comment,
            resId: reservation.id
          },
          headers: {
            'Authorization': `Bearer ${keycloak.token}`
          }
        }
      );

      message.success('Review submitted successfully!');
      onSubmit();
      onClose();
      form.resetFields();
    } catch (error) {
      console.error('Error submitting review:', error);
      message.error('Failed to submit review. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title="Write a Review"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          onClick={handleSubmit}
        >
          Submit Review
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="cuisineRating"
          label="Cuisine Quality"
          rules={[{ required: true, message: 'Please rate the cuisine' }]}
        >
          <Rate />
        </Form.Item>

        <Form.Item
          name="ambianceRating"
          label="Ambiance"
          rules={[{ required: true, message: 'Please rate the ambiance' }]}
        >
          <Rate />
        </Form.Item>

        <Form.Item
          name="valueRating"
          label="Value for Money"
          rules={[{ required: true, message: 'Please rate the value' }]}
        >
          <Rate />
        </Form.Item>

        <Form.Item
          name="cleanlinessRating"
          label="Cleanliness"
          rules={[{ required: true, message: 'Please rate the cleanliness' }]}
        >
          <Rate />
        </Form.Item>

        <Form.Item
          name="comment"
          label="Your Review"
          rules={[
            { required: true, message: 'Please write your review' },
            { min: 10, message: 'Review must be at least 10 characters long' }
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder="Share your experience..."
            style={{ resize: 'none' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReviewModal;