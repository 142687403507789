import React from 'react';
import { Badge, Dropdown, List, Typography, Avatar, Space } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { useNotifications } from './NotificationContext';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const NotificationBell = ({ transparent }) => {
  const { notifications, unreadCount, markAsRead } = useNotifications();
  
  const notificationItems = (
    <List
      className="notification-list"
      style={{ 
        width: '360px', 
        maxHeight: '400px',
        overflow: 'auto',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08)'
      }}
      header={
        <div style={{ padding: '12px 16px', borderBottom: '1px solid #f0f0f0' }}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            Notifications ({unreadCount} non lues)
          </Typography.Title>
        </div>
      }
      dataSource={notifications}
      renderItem={(notification) => (
        <List.Item
          onClick={() => !notification.read && markAsRead(notification.id)}
          className={`cursor-pointer transition-colors ${
            notification.read ? 'bg-white' : 'bg-blue-50 hover:bg-blue-100'
          }`}
          style={{ padding: '12px 16px' }}
        >
          <List.Item.Meta
            avatar={
              <Avatar 
                icon={<BellOutlined />} 
                style={{ 
                  backgroundColor: notification.read ? '#d9d9d9' : '#1890ff'
                }}
              />
            }
            title={
              <Space>
                <span>{notification.message}</span>
                {!notification.read && (
                  <Badge status="processing" text="Nouveau" />
                )}
              </Space>
            }
            description={
              <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                {dayjs(notification.timestamp).fromNow()}
              </Typography.Text>
            }
          />
        </List.Item>
      )}
      locale={{
        emptyText: (
          <div style={{ padding: '24px', textAlign: 'center' }}>
            <Typography.Text type="secondary">
              Pas de notifications
            </Typography.Text>
          </div>
        )
      }}
    />
  );

  return (
    <Dropdown 
      overlay={notificationItems} 
      trigger={['click']}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
    >
      <Badge count={unreadCount} size="small">
        <BellOutlined 
          className={`text-2xl cursor-pointer transition-colors ${
            transparent ? 'text-white' : 'text-gray-600'
          } hover:text-primary`}
        />
      </Badge>
    </Dropdown>
  );
};


export default NotificationBell;