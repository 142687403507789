import React, { useEffect, useState } from 'react';
import { Button, Tooltip, Badge } from 'antd';
import { StarOutlined } from '@ant-design/icons';

const AnimatedReviewButton = ({ record, onReviewClick }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (!record.reviewed) {
      // Create attention-grabbing animation loop
      const interval = setInterval(() => {
        setAnimate(true);
        setTimeout(() => setAnimate(false), 1000);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [record.reviewed]);

  return (
    <Badge dot={!record.reviewed} status="warning">
      <Tooltip title="Write Review">
        <Button
          type={!record.reviewed ? "primary" : "default"}
          icon={<StarOutlined className={animate ? 'animate-bounce' : ''} />}
          onClick={onReviewClick}
          className={`transition-all duration-300 ${
            !record.reviewed ? 'animate-pulse hover:scale-105' : ''
          }`}
        >
          {!record.reviewed && "Écrire un avis"}
        </Button>
      </Tooltip>
    </Badge>
  );
};

export default AnimatedReviewButton;