import React, { useEffect, useState } from 'react';
import { 
  Table, 
  Typography, 
  Space, 
  Tag, 
  Button, 
  Modal, 
  Select, 
  DatePicker, 
  Input, 
  Dropdown, 
  Menu, 
  message, 
  Tooltip,
  Popconfirm,
  Card,
  Row,
  Col,
  Statistic,Badge 
} from 'antd';
import { 
  EyeOutlined, 
  FilterOutlined, 
  DownloadOutlined, 
  PrinterOutlined, 
  MoreOutlined,
  DollarOutlined,
  UserOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import { Descriptions, Avatar, Divider } from 'antd';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend } from 'recharts';
import axios from 'axios';
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { CSVLink } from "react-csv";
import NavHote from '../../Host/NavHote';
import DashboardCharts from './DashboardCharts';
import { StarOutlined } from '@ant-design/icons';
import ReviewModal from './ReviewModal';
import AnimatedReviewButton from './utilities/AnimatedReviewButton';
const { Title, Text } = Typography;
const { Option } = Select;

const Dashboard = () => {
  const [reservations, setReservations] = useState([]);
  const [filteredReservations, setFilteredReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});
  const [monthlyStats, setMonthlyStats] = useState([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [filters, setFilters] = useState({
    status: null,
    dateRange: null,
    searchTerm: ''
  });
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const calculateStats = (reservations) => {
    const totalReservations = reservations.length;
    const totalRevenue = reservations.reduce((acc, r) => acc + (r.prixTotal || 0), 0);
    const pendingReservations = reservations.filter(r => r.status === 'PENDING').length;
    const completedReservations = reservations.filter(r => r.status === 'APPROVED').length;

    const groupedByMonth = reservations.reduce((acc, r) => {
      const month = moment(r.reservationDate).format('YYYY-MM');
      acc[month] = (acc[month] || 0) + 1;
      return acc;
    }, {});

    const monthlyStats = Object.entries(groupedByMonth).map(([date, count]) => ({
      date,
      reservations: count,
    }));
    setStats({ totalReservations, totalRevenue, pendingReservations, completedReservations, monthlyStats });
    return { totalReservations, totalRevenue, pendingReservations, completedReservations, monthlyStats };
  };
  // Export Data Preparation
  const exportReservations = [
    ['ID', 'Client', 'Annonce', 'Invités', 'Date', 'Montant', 'Statut'],
    ...filteredReservations.map(r => [
      r.id,
      r.user?.nom,
      r.experienceCulinaire.titre,
      r.nombreInvited,
      moment(r.reservationDate).format('DD/MM/YYYY'),
      `${r.prixTotal} MAD`,
      r.status
    ])
  ];

  // Table Columns Definition
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => index + 1
    },
    {
      title: keycloak.hasRealmRole("Host") ? "Client" : "Hôte",
      render: (record) => keycloak.hasRealmRole("Host") ? record.user?.nom : record.host?.nom
    },
    {
      title: 'Annonce',
      render: (record) => (
        <a onClick={() => navigate('/annonce', { state: record.experienceCulinaire })}>
          {record.experienceCulinaire.titre}
        </a>
      )
    },
    {
      title: 'Nombre d\'invités',
      dataIndex: 'nombreInvited',
      key: 'nombreInvited'
    },
    {
      title: 'Date',
      render: (record) => moment(record.reservationDate).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'Montant',
      render: (record) => `${record.prixTotal} MAD`
    },
    {
      title: 'Statut',
      render: (record) => {
        const statusColors = {
          'PENDING': 'orange',
          'PAYED': 'green',
          'ENCOURS': 'blue',
          'TERMINE': 'purple',
          'APPROVED': 'green',
          'REJECTED': 'red',
          'CANCELED': 'red'
        };
        return <Tag color={statusColors[record.status]}>{record.status}</Tag>;
      }
    },
    {
      title: 'Actions',
      render: (record) => (
        <Space>
          <Tooltip title="View Details">
            <Button 
              icon={<EyeOutlined />} 
              onClick={() => {
                setSelectedReservation(record);
                setModalVisible(true);
              }}
            />
          </Tooltip>
          <Dropdown
            overlay={
              <Menu>
                {keycloak.hasRealmRole("Host") && (
                  <>
                    {
                      record.status=="PENDING" &&
                    <Menu.Item 
                      key="accept" 
                      icon={<CheckCircleOutlined />}
                      onClick={() => handleReservationAction('accept', record)}
                    >
                      Accepter
                    </Menu.Item>
            }
                    { 
                    record.status=="PENDING" &&
                    <Menu.Item 
                      key="reject" 
                      icon={<CloseCircleOutlined />}
                      onClick={() => handleReservationAction('reject', record)}
                    >
                      Rejeter
                    </Menu.Item>
                    }
                  </>
                )}
                {
                  record.status !== 'COMPLETED' ?
                <Menu.Item 
                  key="cancel" 
                  icon={<CloseCircleOutlined />}
                  onClick={() => handleReservationAction('cancel', record)}
                >
                  Annuler
                </Menu.Item>:<></>
            }
                { keycloak.hasRealmRole("Host") && record.status=="APPROVED" ?
                <Menu.Item 
                  key="complete" 
                  icon={<CloseCircleOutlined />}
                  onClick={() => handleReservationAction('complete', record)}
                >
                  Terminé
                </Menu.Item>:
                <></>
                }
              </Menu>
            }
            trigger={['click']}
          >
            <Button icon={<MoreOutlined />} />
          </Dropdown>
          {record.status === 'COMPLETED' && keycloak.hasRealmRole("Client")&& (
        <Tooltip title="Write Review">
          {
            /*
          <Button 
            icon={<StarOutlined />}
            onClick={() => {
              console.log(record)
              setSelectedReservation(record);
              setReviewModalVisible(true);
            }}
          />*/
    }
          <AnimatedReviewButton
            record={record}
            onReviewClick={() => {
              console.log(record);
              setSelectedReservation(record);
              setReviewModalVisible(true);
            }}
          />
        </Tooltip>
      )}
        </Space>
      )
    }
  ];

  // Fetch all data
  const fetchData = async () => {
    setLoading(true);
    try {
      const reservationsResponse = await axios.get(
        keycloak.hasRealmRole("Client")
          ? `${process.env.REACT_APP_API_SER}reservation/getallu`
          : `${process.env.REACT_APP_API_SER}reservation/getallh`,
        {
          headers: { 'Authorization': `Bearer ${keycloak.token}` }
        }
      );
      setReservations(reservationsResponse.data);
      setFilteredReservations(reservationsResponse.data.reverse());
      console.log(reservationsResponse.data);
      if (keycloak.hasRealmRole("Host")) {
        const statsResponse = await axios.get(
          `${process.env.REACT_APP_API_SER}reservation/stats`,
          {
            headers: { 'Authorization': `Bearer ${keycloak.token}` }
          }
        );
        setStats(statsResponse.data);

        const dashboardResponse = await axios.get(
          `${process.env.REACT_APP_API_SER}reservation/dashboard`,
          {
            headers: { 'Authorization': `Bearer ${keycloak.token}` }
          }
        );
        const chartData = Object.entries(dashboardResponse.data).map(([date, count]) => ({
          date,
          reservations: count
        }));
        setMonthlyStats(chartData);
      }
    } catch (error) {
      message.error('Error fetching data');
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [keycloak]);

  // Reservation Actions
  const handleReservationAction = async (action, reservation) => {
    try {
      const actionUrls = {
        'accept': `http://localhost:8080/reservation/reservationStatus/${reservation.id}/APPROVED`,
        'reject': `http://localhost:8080/reservation/reservationStatus/${reservation.id}/REJECTED`,
        //'cancel': `${process.env.REACT_APP_API_SER}reservation/reservationStatus/${reservation.id}/CANCELED`,
        'cancel': `http://localhost:8080/reservation/reservationStatus/${reservation.id}/CANCELED`,
        //'complete': `${process.env.REACT_APP_API_SER}reservation/reservationStatus/${reservation.id}/COMPLETED`,
        'complete': `http://localhost:8080/reservation/reservationStatus/${reservation.id}/COMPLETED`,
      };

      await axios.post(actionUrls[action], {}, {
        headers: { 'Authorization': `Bearer ${keycloak.token}` }
      });
      const actionMessages = {
        'accept': 'acceptée',
        'reject': 'rejetée',
        'cancel': 'annulée',
    };

    message.success(`Réservation ${actionMessages[action] || 'traitée'} avec succès`);
      fetchData();
      calculateStats(reservations);
    } catch (error) {
      message.error(`Erreur lors de l'action ${action}`);
      console.error(`Error ${action} reservation:`, error);
    }
  };

  // Stats Cards Component
  const StatsCards = () => (
    <Row gutter={16} style={{ marginBottom: 24 }}>
      <Col span={6}>
        <Card>
          <Statistic title="Total Reservations" value={stats.totalReservations} prefix={<UserOutlined />} />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic title="Pending Reservations" value={stats.pendingReservations} prefix={<CloseCircleOutlined />} />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic title="Completed Reservations" value={stats.completedReservations} prefix={<CheckCircleOutlined />} />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic title="Total Revenue" value={stats.totalRevenue} prefix={<DollarOutlined />} suffix="MAD" />
        </Card>
      </Col>
    </Row>
  );

  // Monthly Stats Chart Component
  const MonthlyStatsChart = () => {
    if (!monthlyStats.length || !keycloak.hasRealmRole("Host")) return null;

    return (
      <Card style={{ marginBottom: 24 }}>
        <Title level={4}>Monthly Reservations</Title>
        <LineChart
          width={800}
          height={300}
          data={monthlyStats}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <RechartsTooltip />
          <Legend />
          <Line type="monotone" dataKey="reservations" stroke="#8884d8" />
        </LineChart>
      </Card>
    );
  };

  return (
    <>
    <NavHote></NavHote>
      <Space direction="vertical" size="large" style={{ display: 'flex', padding: 24, marginTop: 120 }}>
        <Title level={2}>My Reservations</Title>
        
        {
          //<StatsCards />
          }
        
        
        <Space style={{ marginBottom: 16 }}>
          <Button 
            icon={<FilterOutlined />} 
            onClick={() => setFilterModalVisible(true)}
          >
            Filters
          </Button>
          <CSVLink data={exportReservations} filename={"reservations.csv"}>
            <Button icon={<DownloadOutlined />}>Export</Button>
          </CSVLink>
          <Button icon={<PrinterOutlined />} onClick={() => window.print()}>
            Print
          </Button>
        </Space>

        <Table 
          columns={columns} 
          dataSource={filteredReservations}
          loading={loading}
          rowKey="id"
          pagination={{ 
            showSizeChanger: true, 
            pageSizeOptions: ['10', '20', '50', '100'] 
          }}
        />

        {/* Reservation Details Modal */}
        
        <Modal
  title={<Title level={4}>Détails de la Réservation</Title>}
  visible={modalVisible}
  onCancel={() => setModalVisible(false)}
  footer={null}
>
  {selectedReservation && (
    <Space direction="vertical" style={{ width: '100%' }}>
      {/* Reservation Details */}
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Annonce">
          {selectedReservation.experienceCulinaire.titre}
        </Descriptions.Item>
        <Descriptions.Item label="Date de Réservation">
          {moment(selectedReservation.reservationDate).format('DD/MM/YYYY HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label="Nombre d'Invités">
          {selectedReservation.nombreInvited}
        </Descriptions.Item>
        <Descriptions.Item label="Montant Total">
          {selectedReservation.prixTotal} MAD
        </Descriptions.Item>
        <Descriptions.Item label="Statut">
          <Badge
            status={
              selectedReservation.status === 'CANCELED'
                ? 'error'
                : selectedReservation.status === 'APPROVED'
                ? 'success'
                : 'warning'
            }
            text={selectedReservation.status}
          />
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      {/* Client Details */}
      <Title level={5}>Informations du Client</Title>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Nom">
          {selectedReservation.user.nom} {selectedReservation.user.prenom}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{selectedReservation.user.email}</Descriptions.Item>
        <Descriptions.Item label="Numéro de Téléphone">
          {selectedReservation.user.numTele || 'Non disponible'}
        </Descriptions.Item>
        <Descriptions.Item label="Ville">
          {selectedReservation.user.city || 'Non spécifiée'}
        </Descriptions.Item>
        <Descriptions.Item label="Langues">
          {selectedReservation.user.langues || 'Non spécifiées'}
        </Descriptions.Item>
        <Descriptions.Item label="Passions">
          {selectedReservation.user.passions || 'Non spécifiées'}
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {selectedReservation.user.bio || 'Non spécifiée'}
        </Descriptions.Item>
      </Descriptions>
      
      <Divider />

      {/* Client Avatar */}
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Avatar
          size={120}
          src={selectedReservation.user.avatar}
          alt={`${selectedReservation.user.nom} ${selectedReservation.user.prenom}`}
        />
        <Text type="secondary">
          Client: {selectedReservation.user.nom} {selectedReservation.user.prenom}
        </Text>
      </Space>
    </Space>
  )}
</Modal>

        {/* Filter Modal */}
        <Modal
          title="Filtres Avancés"
          visible={filterModalVisible}
          onOk={() => {
            // Apply filters logic here
            setFilterModalVisible(false);
          }}
          onCancel={() => setFilterModalVisible(false)}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Select
              style={{ width: '100%' }}
              placeholder="Filtrer par Statut"
              allowClear
              onChange={(value) => setFilters({...filters, status: value})}
            >
              <Option value="PENDING">En attente</Option>
              <Option value="APPROVED">Approuvé</Option>
              <Option value="REJECTED">Rejeté</Option>
              <Option value="CANCELED">Annulé</Option>
              {
                keycloak.hasRealmRole("Host") ?
                        <Option value="COMPLETED">Terminé</Option>:<></>
              }
            </Select>
            
            <DatePicker.RangePicker 
              style={{ width: '100%' }}
              onChange={(dates) => setFilters({...filters, dateRange: dates})}
            />
            
            <Input 
              placeholder="Rechercher par nom ou titre" 
              onChange={(e) => setFilters({...filters, searchTerm: e.target.value})}
            />
          </Space>
        </Modal>
        {keycloak.hasRealmRole("Client") ?
        <ReviewModal
  visible={reviewModalVisible}
  reservation={selectedReservation}
  onClose={() => setReviewModalVisible(false)}
  onSubmit={() => {
    fetchData(); // Refresh data after review submission
  }}
/>:<></>}
        <MonthlyStatsChart />
      </Space>
    </>
  );
};

export default Dashboard;