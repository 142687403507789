import React, { useEffect, useState } from 'react';
import { Card, Statistic, Row, Col, Progress, Spin } from 'antd';
import { 
  DollarOutlined, 
  ShopOutlined, 
  StarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
const HostStatsDashboard = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {keycloak}=useKeycloak();
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_SER}api/host/stats`, {
          headers: {
            'Authorization': `Bearer ${keycloak.token}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch stats');
        }
        
        const data = await response.json();
        setStats(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [keycloak.token]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        Error: {error}
      </div>
    );
  }

  return (
    <div className="p-4">
      <Row gutter={[16, 16]}>
        {/* Financial Metrics */}
        <Col xs={24} sm={12} lg={8}>
          <Card>
            <Statistic
              title="Revenue"
              value={stats.chiffreAffaires}
              prefix={<DollarOutlined />}
              precision={2}
              suffix="MAD"
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <Card>
            <Statistic
              title="Average Revenue"
              value={stats.revenuMoyen}
              prefix={<DollarOutlined />}
              precision={2}
              suffix="MAD"
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <Card>
            <Statistic
              title="Revenue Potentiel"
              value={stats.revenuPotentiel}
              prefix={<DollarOutlined />}
              precision={2}
              suffix="MAD"
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <Card>
            <Statistic
              title="Total Commission"
              value={stats.commisionTotal}
              prefix={<DollarOutlined />}
              precision={2}
              suffix="MAD"
            />
          </Card>
        </Col>

        {/* Performance Metrics */}
        <Col xs={24} sm={12} lg={8}>
          <Card>
            <Statistic
              title="Reservation Rate"
              value={Math.floor(stats.tauxReservation)}
              suffix="%"
              prefix={<ShopOutlined />}
            />
            <Progress percent={Math.floor(stats.tauxReservation)} status="active" />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <Card>
            <Statistic
              title="Cancellation Rate"
              value={Math.floor(stats.pourcentageAnnulation)}
              suffix="%"
              prefix={<CloseCircleOutlined />}
            />
            <Progress percent={Math.floor(stats.pourcentageAnnulation)} status="exception" />
          </Card>
        </Col>

        {/* Experience Status */}
        <Col xs={24}>
  <Card title="Statut des Expériences">
    <Row gutter={[16, 16]} justify="space-between">
      <Statistic
        title="En cours"
        value={stats.nbrExpEncours}
        prefix={<ClockCircleOutlined />}
        style={{ display: "inline-block", margin: "0 16px" }}
      />
      <Statistic
        title="Validées"
        value={stats.nbrExpValide}
        prefix={<CheckCircleOutlined />}
        style={{ display: "inline-block", margin: "0 16px" }}
      />
      <Statistic
        title="Refusées"
        value={stats.nbrExpRefuse}
        prefix={<CloseCircleOutlined />}
        style={{ display: "inline-block", margin: "0 16px" }}
      />
      <Statistic
        title="Terminées"
        value={stats.nbrExpCompleted}
        prefix={<CheckCircleOutlined />}
        style={{ display: "inline-block", margin: "0 16px" }}
      />
      <Statistic
        title="Annulations"
        value={stats.nbrAnnulation}
        prefix={<CloseCircleOutlined />}
        style={{ display: "inline-block", margin: "0 16px" }}
      />
    </Row>
  </Card>
</Col>


        {/* Ratings */}
        <Col xs={24}>
          <Card title="Ratings">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <Card>
                  <Statistic
                    title="Overall Rating"
                    value={stats.rating}
                    prefix={<StarOutlined />}
                    precision={1}
                    suffix="/5"
                  />
                  <Progress percent={Math.floor((stats.rating / 5) * 100)} />
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card>
                  <Statistic
                    title="Cuisine Rating"
                    value={stats.cuisineRating/stats.nbrReview}
                    prefix={<StarOutlined />}
                    precision={1}
                    suffix="/5"
                  />
                  <Progress percent={(stats.cuisineRating / 5*stats.nbrReview) * 100} />
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card>
                  <Statistic
                    title="Ambiance Rating"
                    value={stats.ambianceRating/stats.nbrReview}
                    prefix={<StarOutlined />}
                    precision={1}
                    suffix="/5"
                  />
                  <Progress percent={(stats.ambianceRating / 5*stats.nbrReview) * 100} />
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card>
                  <Statistic
                    title="Value Rating"
                    value={stats.valueRating/stats.nbrReview}
                    prefix={<StarOutlined />}
                    precision={1}
                    suffix="/5"
                  />
                  <Progress percent={(stats.valueRating / 5*stats.nbrReview) * 100} />
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card>
                  <Statistic
                    title="Cleanliness Rating"
                    value={stats.cleanlinessRating/stats.nbrReview}
                    prefix={<StarOutlined />}
                    precision={1}
                    suffix="/5"
                  />
                  <Progress percent={(stats.cleanlinessRating / 5*stats.nbrReview) * 100} />
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HostStatsDashboard;