import React, { createContext, useContext, useState, useEffect } from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { useKeycloak } from '@react-keycloak/web';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const { keycloak } = useKeycloak();
    const [stompClient, setStompClient] = useState(null);

    useEffect(() => {
        if (keycloak.authenticated) {
            // Connect to WebSocket
            const socket = new SockJS('http://localhost:8080/ws');
            const client = Stomp.over(socket);

            client.connect({ 'Authorization': `Bearer ${keycloak.token}` }, () => {
                console.log('WebSocket Connected');
                
                // Subscribe to user-specific notifications
                client.subscribe(`/user/${keycloak.tokenParsed.email}/topic/notifications`, (message) => {
                    const notification = JSON.parse(message.body);
                    setNotifications(prev => [notification, ...prev]);
                    setUnreadCount(prev => prev + 1);
                });
            });

            setStompClient(client);

            // Fetch existing notifications
            fetchNotifications();
            fetchUnreadCount();

            return () => {
                if (client) {
                    client.disconnect();
                }
            };
        }
    }, [keycloak.authenticated]);

    const fetchNotifications = async () => {
        try {
            const response = await fetch('http://localhost:8080/api/notifications', {
                headers: {
                    'Authorization': `Bearer ${keycloak.token}`
                }
            });
            const data = await response.json();
            setNotifications(data);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    const fetchUnreadCount = async () => {
        try {
            const response = await fetch('http://localhost:8080/api/notifications/unread-count', {
                headers: {
                    'Authorization': `Bearer ${keycloak.token}`
                }
            });
            const count = await response.json();
            setUnreadCount(count);
        } catch (error) {
            console.error('Error fetching unread count:', error);
        }
    };

    const markAsRead = async (notificationId) => {
        try {
            await fetch(`http://localhost:8080/api/notifications/${notificationId}/mark-read`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${keycloak.token}`
                }
            });
            setUnreadCount(prev => Math.max(0, prev - 1));
            setNotifications(prev => 
                prev.map(notif => 
                    notif.id === notificationId 
                        ? { ...notif, read: true }
                        : notif
                )
            );
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    return (
        <NotificationContext.Provider value={{
            notifications,
            unreadCount,
            markAsRead
        }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => useContext(NotificationContext);