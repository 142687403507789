import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Starts from './Starts.js';
import Features from './Features.js';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardMedia,
  Skeleton,
} from '@mui/material';
import axios from 'axios';
import "./Plats.css";
export default function Plats() {
  const [selectedCity, setSelectedCity] = useState('Rabat');
  const [plats, setPlats] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const filteredPlats = plats.filter((plat) => selectedCity === plat.lieu);
  const cities = [...new Set(plats.map((plat) => plat.lieu))];
  
  console.log("hooooooooooooooooooooo2");
            function handleClick(direction) {
                const list = document.querySelector(".list");

            // We want to know the width of one of the items. We'll use this to decide how many pixels we want our carousel to scroll.
            const item = document.querySelector(".item");
            const itemWidth = item.offsetWidth;
                // Based on the direction we call `scrollBy` with the item width we got earlier
                if(direction === "previous") {
                list.scrollBy({ left: -itemWidth, behavior: "smooth" });
                } else {
                list.scrollBy({ left: itemWidth, behavior: "smooth" });
                }
            }
  useEffect(() => {
    const fetchPlats = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_SER + 'experience/0/1200'
        );
        setPlats(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching plats:', error);
        setLoading(false);
      }
    };
    fetchPlats();
  }, []);

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  return (
    <div>
      <Grid container spacing={1} alignItems="center" style={{marginBottom:"0px"}}>
        <Grid item>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: '16px', sm: '18px', md: '24px', lg: '24px' },
              whiteSpace: 'nowrap',
              fontFamily: 'Poppins',
            }}
          >
            Trends Top Plats à :
          </Typography>
        </Grid>

        <Grid item>
          <Select
            value={selectedCity}
            onChange={handleCityChange}
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 'lighter',
              fontSize: { xs: '16px', sm: '18px', md: '24px', lg: '24px' },
              ml: 1,
              width: 'auto',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiSelect-select': {
                paddingY: { xs: 0.5, sm: 1, md: 1.5 },
                paddingX: { xs: 1.5, sm: 2, md: 2.5 },
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  borderRadius: 0,
                  maxHeight: '300px',
                  width: { xs: '100%', sm: 'auto' },
                },
              },
            }}
          >
            {cities.map((lieu) => (
              <MenuItem key={lieu} value={lieu}>
                {lieu}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} md={6} style={{marginBottom:"0px"}}>
          <Features />
        </Grid>
      </Grid>
      <div class="list-wrapper">
      <ul className="list">
        {loading ? (
          Array.from(new Array(4)).map((_, index) => (
            <li key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
              <div className="h-full">
                <Card className="h-full border-none shadow-none">
                  <div className="aspect-square w-full">
                    <Skeleton variant="rectangular" className="w-full h-full" />
                  </div>
                  <CardContent className="font-poppins">
                    <Skeleton variant="text" className="w-4/5 mb-2" />
                    <Skeleton variant="text" className="w-3/5 mb-2" />
                  </CardContent>
                  <Skeleton variant="text" className="w-2/5 mx-4" />
                </Card>
              </div>
            </li>
          
          ))
        ) : (
          filteredPlats.map((e, index) => (
            <li key={index} className="item">
              <div className="content">
                <Grid style={{ borderStyle: 'none' }}>
                  <Card
                    style={{
                      marginTop: '0px',
                      padding: '0px',
                      height: '350px',
                      borderStyle: 'none',
                      boxShadow: 'none',
                      width: '258px',
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={`${process.env.REACT_APP_API_SER}photos/${e.photos[0].id}`}
                      alt="Image"
                      loading="lazy"
                      style={{
                        height: '230px',
                        width: '230px',
                        cursor: 'pointer',
                        borderRadius: '5px',
                      }}
                      sx={{
                        '&:hover': {
                          transform: 'scale(1.05)',
                          transition: 'transform 0.3s ease-in-out',
                        },
                      }}
                      onClick={() => navigate('/annonce', { state: e })}
                    />
                    <CardContent
                      sx={{
                        fontFamily: 'Poppins',
                        marginLeft: '-15px',
                      }}
                    >
                      <div
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          lineClamp: 2,
                        }}
                      >
                        {e.description}
                      </div>
                      <div style={{ marginBottom: '9px' }} className="prices">
                        <Typography
                          style={{ fontFamily: 'Poppins' }}
                          color="orange"
                        >
                          {e.prixParInvite} MAD
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: 'Poppins',
                            color: 'black',
                            opacity: '0.5',
                          }}
                        >
                          {e.prixParInvite + 50} MAD
                        </Typography>
                      </div>
                    </CardContent>
                    <div
                      sx={{
                        fontFamily: 'Poppins',
                        marginLeft: '-15px',
                      }}
                    >
                      <Starts
                        stars={e.rating === 0 ? e.rating + 5 : e.rating}
                        reviews={e.nombreAvis}
                      />
                    </div>
                  </Card>
                </Grid>
              </div>
            </li>
          ))
        )}
      </ul>
      <button class="button button--previous" type="button" onClick={()=>handleClick("previous")}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7L15 12L10 17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
      </button>
  <button class="button button--next" type="button" onClick={handleClick}>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7L15 12L10 17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  </button>
         </div>
    </div>
  );
}
