import React, { useState, useEffect } from 'react';
import ReviewModal from "./ReviewModal";
import axios from 'axios';
import { useKeycloak } from "@react-keycloak/web";

export function AddReview() {
  const { keycloak } = useKeycloak();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingReviews, setPendingReviews] = useState([]);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    const fetchPendingReviews = async () => {
      if (keycloak.authenticated && keycloak.tokenParsed) {
        const email = keycloak.tokenParsed.email;
        const roles = keycloak.tokenParsed.realm_access?.roles || [];
        setIsClient(roles.includes('Client'));

        if (isClient) {
          try {
            const userResponse = await axios.get(`${process.env.REACT_APP_API_SER}api/user/userIdByEmail`, {
              headers: { Authorization: `Bearer ${keycloak.token}` },
              params: { email }
            });

            const userId = userResponse.data;

            const response = await axios.get(`${process.env.REACT_APP_API_SER}reservation/pendingReviews/${userId}`, {
              headers: { Authorization: `Bearer ${keycloak.token}` }
            });

            const reservationsWithDetails = await Promise.all(response.data.map(async (reservation) => {
              const experienceResponse = await axios.get(`${process.env.REACT_APP_API_SER}experience/titre/${reservation.experienceCulinaire.id}`, {
                headers: { Authorization: `Bearer ${keycloak.token}` }
              });
              const hostResponse = await axios.get(`${process.env.REACT_APP_API_SER}api/host/Prenom/${reservation.host.id}`, {
                headers: { Authorization: `Bearer ${keycloak.token}` }
              });
              return {
                ...reservation,
                experienceTitle: experienceResponse.data,
                hostFirstName: hostResponse.data
              };
            }));

            setPendingReviews(reservationsWithDetails);
            setIsModalOpen(reservationsWithDetails.length > 0); 
          } catch (error) {
            console.error('Erreur lors de la récupération des réservations non évaluées:', error);
          }
        }
      }
    };

    fetchPendingReviews();
  }, [keycloak.authenticated, keycloak.tokenParsed, isClient]);

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      {isModalOpen && pendingReviews.map((reservation) => (
        <ReviewModal
          key={reservation.id}
          experienceId={reservation.experienceCulinaire.id}
          experienceTitle={reservation.experienceTitle}
          hostFirstName={reservation.hostFirstName}
          reservationId={reservation.id}
          onClose={closeModal}
        />
      ))}
    </>
  );
}
