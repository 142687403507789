import React, { useState, useEffect } from "react";
import axios from "axios";
import { Avatar, Box, Typography, Button, Grid } from "@mui/material";
import { UserOutlined } from "@ant-design/icons";
import RatingInfos from "./RatingInfos";
import { useParams } from "react-router-dom";
const ReviewResults = ({ id ,e}) => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const reviewsPerPage = 4;

  useEffect(() => {
    fetchReviews();
  }, [id]);

  const fetchReviews = async () => {
    try {
      const response = await axios.get(
        `https://fnfapi.online/api/reviews/getAll/${id}`
      );
      const reviewsData = Array.isArray(response.data) ? response.data : [];
      setReviews(reviewsData);
      console.log("Reviews fetched:", reviewsData);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  const handleShowMore = () => setCurrentPage((prev) => prev + 1);

  const handleShowLess = () => setCurrentPage((prev) => Math.max(0, prev - 1));

  const startIndex = currentPage * reviewsPerPage;
  const visibleReviews = Array.isArray(reviews)
    ? reviews.slice(startIndex, startIndex + reviewsPerPage)
    : [];

  return (
    <Box
      mt={4}
      mb={4}
      sx={{
        width: { xs: "90%", sm: "80%", md: "674px" },
        height: "auto",
        backgroundColor: "#F5F5F5",
        borderRadius: "20px",
        margin: "auto",
        padding: { xs: "20px", md: "40px" },
        overflow: "visible",
      }}
    >
      <RatingInfos e={e}/>
      {visibleReviews.map((review) => (
        <Box key={review.id} mb={2} mt={2} ml={{ xs: 1, md: 8 }}>
          <Grid container alignItems="center" spacing={1} mb={1}>
            <Grid item>
              <Avatar
                src={"https://fnfapi.online/photos/"+review.user.avatar || ""}
                alt={`${review.user.nom} ${review.user.prenom}`}
                sx={{
                  backgroundColor: !review.user.avatar ? "#d7a022" : "transparent",
                  color: !review.user.avatar ? "#fff" : "transparent",
                  width: 56,
                  height: 56,
                }}
              >
                {!review.user.avatar && <UserOutlined />}
              </Avatar>
            </Grid>
            <Grid item xs>
              <Typography sx={{ textAlign: "left" }}>
                {`${review.user.nom} ${review.user.prenom}` || "Utilisateur Anonyme"}
              </Typography>
              <Typography sx={{ textAlign: "left", fontSize: "12px" }}>
                {review.formattedCreatedAt || review.createdAt}
              </Typography>
            </Grid>
          </Grid>
          <Box
            display="flex"
            sx={{
              maxWidth: { xs: "100%", md: "600px" },
              paddingRight: { xs: "0", md: "48px" },
            }}
          >
            <Typography
              variant="body2"
              sx={{
                textAlign: "left",
                whiteSpace: "pre-line",
                overflowWrap: "break-word",
                wordBreak: "break-word",
                marginLeft: { xs: 2, md: 0 },
              }}
            >
              {review.comment || "Aucun commentaire fourni."}
            </Typography>
          </Box>
        </Box>
      ))}
      <Box display="flex" justifyContent="center" mt={2}>
        {startIndex + reviewsPerPage < reviews.length && (
          <Button
            onClick={handleShowMore}
            sx={{
              color: "#d7a022",
              fontWeight: "bold",
              textDecoration: "underline",
              textTransform: "none",
            }}
          >
            Voir tous les avis
          </Button>
        )}
        {currentPage > 0 && (
          <Button
            onClick={handleShowLess}
            sx={{
              marginLeft: 2,
              color: "#d7a022",
              fontWeight: "bold",
              textDecoration: "underline",
              textTransform: "none",
            }}
          >
            Voir moins
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ReviewResults;
