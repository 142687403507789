import React, { useState } from "react";
import {
  Button,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import "./Popup.css";
import { Box } from "@mui/system";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

const ReservationPopup = ({ id, setOpen, prix, guestCount, inforeservation }) => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  
  // Calculate the total price based on guest count and prix per guest
  const totalPrix = guestCount * prix;

  const handleClose = () => {
    reserveExperience(id, keycloak.token);
    setOpen(false);
  };

  const reserveExperience = async (id, token) => {
    try {
      const response = await axios.post(
        //`${process.env.REACT_APP_API_SER}experience/reserver/${id}`,
        `http://localhost:8080/experience/reserver/${id}`,
        
        {
          nombreInvited: guestCount,
          reservationDate: inforeservation,  
          prixTotal: guestCount * prix,
          status: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data); // Should print "reserver avec succes"
      navigate("/reservations");
    } catch (error) {
      console.error("Error reserving experience:", error);
    }
  };

  return (
    <Box className="reservation-popover">
      <Box className="popup-content">
        {/* Tajine Icon */}
        <img
          src="/imgs/tajineicon.png"
          alt="Tajine"
          height="100px"
          className="tajine-icon"
        />
        <br />

        {/* Popup Title */}
        <span className="popup-title">Confirmer votre réservation</span>

        {/* Reservation Details */}
        <div className="reservation-details">
          {/* Number of Guests */}
          <div className="detail-row">
            <InputLabel>Nombre d'invités</InputLabel>
            <Select value={guestCount} className="MuiSelect-root" disabled>
              <MenuItem value={guestCount}>{guestCount} invités</MenuItem>
            </Select>
          </div>

          {/* Reservation Date */
          console.log("inforeservation",inforeservation)
          }
          <div className="detail-row">
            <InputLabel>Date de réservation</InputLabel>
            <Select value={inforeservation} className="MuiSelect-root" disabled>
              <MenuItem value={inforeservation}>{inforeservation}</MenuItem>
            </Select>
          </div>
        </div>

        {/* Cancellation Policy */}
        <p className="cancellation-policy">
          Annulation sans frais jusqu'à 1 jour avant la date de réservation.
        </p>

        {/* Total Price Section */}
        <div className="total-container">
          <div className="total">Total :</div>
          <div className="price">{totalPrix} MAD</div>
        </div>

        {/* Confirm Button */}
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          className="confirm-button"
          sx={{
            backgroundColor: "#e3b13e",
            color: "white",
            fontWeight: 600,
            fontSize: "18px",
            textTransform: "none",
            marginTop: "20px",
            height: "50px",
            width: "100%",
            "&:hover": {
              backgroundColor: "#d7a022",
            },
          }}
        >
          Je confirme ma réservation
        </Button>
      </Box>
    </Box>
  );
};

export default ReservationPopup;
