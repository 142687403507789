import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography, Checkbox, FormGroup, FormControlLabel, Button, Grid, Container, Pagination } from '@mui/material';
import { useKeycloak } from "@react-keycloak/web";
import NavHote from '../../Host/NavHote';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {message} from 'antd';
import HostExperiences from '../hote/HostExperiences';
const checkboxstyle = {
    marginLeft: "10px",
    marginRight: "10px",
    width: 20,
    height: 20,
    position: 'relative',
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        border: '2px solid #B0B0B0',
        borderRadius: '5px',
        backgroundColor: 'white',
    },
    '&.Mui-checked::before': {
        backgroundColor: '#B88454',
        borderColor: '#7C7C7C',
    },
    '&.Mui-checked::after': {
        content: '""',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        color: 'white',
        fontSize: '16px',
    },
};

export default function Experience() {
    const [events, setEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [showFirstComponent, setShowFirstComponent] = useState(true);
    const toggleComponent = () => {
        setShowFirstComponent((prev) => !prev);
      };
    const { keycloak } = useKeycloak();
    const [filters, setFilters] = useState({
        isVerified: false,
        notVerified: false,
        archived: false
    });
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const navigate = useNavigate();

    const fetchExperiences = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_SER}experience/hostn?f=0&e=120`, {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (Array.isArray(data)) {
                setEvents(data.reverse());
                setFilteredEvents(data);
            } else {
                console.error('Expected an array but got:', data);
            }
        } catch (error) {
            console.error('Error fetching experiences:', error);
        }
    };
    useEffect(() => {

        fetchExperiences();
    }, [keycloak]);

    // Filter logic
    useEffect(() => {
        let filtered = events;

        if (filters.isVerified) {
            filtered = filtered.filter(event => event.isVerified && !event.isArchived);
        }

        if (filters.notVerified) {
            filtered = filtered.filter(event => !event.isVerified && !event.isArchived);
        }

        if (filters.archived) {
            filtered = filtered.filter(event => event.isArchived);
        }

        setFilteredEvents(filtered);
        setPage(1); // Reset to the first page after filtering
    }, [events, filters]);

    // Pagination logic
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentEvents = filteredEvents.slice(startIndex, endIndex);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleFilterChange = (filterName) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: !prevFilters[filterName]
        }));
    };
    
    const archiveExperience = async (id) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_SER}experience/archiver/${id}`, {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                    "Content-Type": "application/json",
                    },
            });
            console.log(response.data); // "Experience {id} archiver avec succes"
            message.success('Experience archiver avec succes');
            fetchExperiences();
          return response.data;
        } catch (error) {
          console.error('Error archiving experience:', error.response?.data || error.message);
          throw error;
        }
      };
    const unarchiveExperience = async (id) => {
        try {
            const response = await axios.put(`https://fnfapi.online/experience/desarchiver/${id}`, {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                    "Content-Type": "application/json",
                    },
            });
            console.log(response.data); // "Experience {id} archiver avec succes"
            message.success('Experience archiver avec succes');
            fetchExperiences();
          return response.data;
        } catch (error) {
          console.error('Error archiving experience:', error.response?.data || error.message);
          throw error;
        }
      };

    return (
        <>
            <NavHote />
           
            <Container>
            
                <div style={{ marginTop: "200px" }}></div>
                <Button 
                    variant="contained" 
                    style={{ float: "right", backgroundColor: "#D7A022", fontFamily: "Poppins" }}  onClick={toggleComponent}>{
                showFirstComponent ? "Gestion des récurrences":"Gestion des annonces"
                }
                 </Button>
        {showFirstComponent ? 
                <>
                <Button 
                    variant="contained" 
                    style={{ float: "right", backgroundColor: "#D7A022", fontFamily: "Poppins" }} 
                    onClick={() => navigate("/newannonce")}
                >
                    Déposer une nouvelle annonce
                </Button>
                <Typography variant="h4">Mes annonces</Typography>

                <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
                    <Pagination
                        count={Math.ceil(filteredEvents.length / itemsPerPage)}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Grid>

                <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item xs={3}>
                        <FormGroup style={{
                            backgroundColor: "#F8F8F8",
                            border: "1px solid #B0B0B0",
                            borderRadius: "8px",
                            padding: "16px",
                            gap: "10px",
                            fontFamily: "Poppins"
                        }}>
                            <Typography variant="h6" style={{ marginBottom: "8px" }}>
                                Filters
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={checkboxstyle}
                                        checked={filters.isVerified}
                                        onChange={() => handleFilterChange('isVerified')}
                                    />
                                }
                                label="Vérifié"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={checkboxstyle}
                                        checked={filters.notVerified}
                                        onChange={() => handleFilterChange('notVerified')}
                                    />
                                }
                                label="Non vérifié"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={checkboxstyle}
                                        checked={filters.archived}
                                        onChange={() => handleFilterChange('archived')}
                                    />
                                }
                                label="Archivé"
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={9}>
                        <Grid container spacing={3}>
                            {currentEvents.map(event => (
                                <Grid item xs={12} sm={6} md={4} key={event.id}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        style={{ height: "230px" }}
                                        image={`${process.env.REACT_APP_API_SER}photos/${event.photos[0].id}`}
                                        alt={event.titre}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {event.titre}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {event.description}
                                        </Typography>
                                    </CardContent>
                                    <Button onClick={() => navigate('/annonce', { state: event })} style={{ width: "100%", backgroundColor: "rgba(225, 189, 62, 0.21)", color: "#B88454", fontSize: "10px", fontFamily: "Poppins" }}>
                                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.48089 2.06128H2.62671C1.85869 2.06128 1.23608 2.68388 1.23608 3.4519V8.08736C1.23608 8.85538 1.85869 9.47798 2.62671 9.47798H7.26215C8.03018 9.47798 8.65278 8.85538 8.65278 8.08736V6.23315M6.33484 2.06139L8.65278 2.06128M8.65278 2.06128V4.14727M8.65278 2.06128L4.71232 6.00127" stroke="#B88454" stroke-width="0.824078" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        &nbsp;&nbsp;
                                        Voir l'apperçu
                                    </Button>
                                    <Button onClick={() => navigate('/edit-annonce', { state: event })} style={{ width: "100%", backgroundColor: "rgba(62, 189, 225, 0.21)", color: "#4487B8", fontSize: "10px", fontFamily: "Poppins" }}>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 11H11M1 6.5L6.5 1L8.5 3L3 8.5H1V6.5Z" stroke="#4487B8" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        &nbsp;&nbsp;
                                        Éditer
                                    </Button>
                                    {
                                    !event.isArchived ?
                                    <Button style={{ width: "100%", backgroundColor: "rgba(124, 124, 124, 0.10)", color: "#7C7C7C", fontSize: "10px", fontFamily: "Poppins" }}
                                    onClick={()=>archiveExperience(event.id)}
                                    >
                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.92285 3.8131H9.61424M4.32641 2.28613H7.21068M4.80712 8.90299V5.84906M6.72997 8.90299V5.84906M7.45104 10.9389H4.08606C3.55508 10.9389 3.12463 10.4832 3.12463 9.92097L2.90514 4.34328C2.89376 4.05411 3.11209 3.8131 3.38543 3.8131H8.15166C8.425 3.8131 8.64334 4.05411 8.63196 4.34328L8.41246 9.92097C8.41246 10.4832 7.98202 10.9389 7.45104 10.9389Z" stroke="#7C7C7C" stroke-width="0.961424" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        &nbsp;&nbsp;
                                        Archiver
                                    </Button>:
                                    <Button
                                    style={{
                                      width: "100%",
                                      backgroundColor: "rgba(0, 200, 83, 0.10)", // Light green to reflect "restore" action
                                      color: "#00C853", // Green color for text and icon
                                      fontSize: "10px",
                                      fontFamily: "Poppins",
                                    }}
                                    onClick={() => unarchiveExperience(event.id)}
                                  >
                                    <svg
                                      width="12"
                                      height="13"
                                      viewBox="0 0 12 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 10V4M6 4L3 7M6 4L9 7"
                                        stroke="#00C853"
                                        strokeWidth="0.961424"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M3 10H9C9.55228 10 10 9.55228 10 9V3C10 2.44772 9.55228 2 9 2H3C2.44772 2 2 2.44772 2 3V9C2 9.55228 2.44772 10 3 10Z"
                                        stroke="#00C853"
                                        strokeWidth="0.961424"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    &nbsp;&nbsp;
                                    Désarchiver
                                  </Button>
                                  
                                  
                                        }
                                </Card>
                            </Grid>
                            ))}
                        </Grid>
                       
                    </Grid>
                </Grid></>
                : <HostExperiences></HostExperiences>}
            </Container>
            <Footer />
        </>
    );
}
