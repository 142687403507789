import { Button } from "@mui/base/Button";
import DrawerAppBar from "./components/DrawerAppBar";
import { Search } from "@mui/icons-material";
import SearchBy from "./components/SearchBy";
import Experiences from "./components/Experiences";
import { Box } from "@mui/material";
import Plats from "./components/Plats";
import TopHote from "./components/TopHote";
import Carousel from "./components/TestimonialCarousel";
import TestimonialCarousel from "./components/TestimonialCarousel";
import Footer from "./components/Footer";
import { Container } from "@mui/system";
import { isEdge, isFirefox, isChrome } from "react-device-detect";
import { AddReview } from "./components/ReviewModal/AddReview";

import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import {message} from "antd";
import { useEffect } from "react";
import NotificationBell from "./components/NotificationSys/NotificationBell";
import {NotificationProvider} from "./components/NotificationSys/NotificationContext";
//import NotificationTest from "./Client/NotificationTest";
export function Home() {
  const { keycloak } = useKeycloak();
  
  const navigate = useNavigate();
  useEffect(() => {
    if (keycloak.authenticated) {
      console.log("Authenticated");

      if (keycloak.hasRealmRole("Client")) {
        navigate("/");
      } else if (keycloak.hasRealmRole("Profile")) {
        navigate("/hostProfile");
      }

      if (keycloak.hasRealmRole("Client")) {
        message.open({
          content: (
            <div style={{ textAlign: "center" }}>
              <p>Bienvenue {keycloak.tokenParsed?.name}</p>
              <p>✨Prêt à vivre des expériences inoubliables ? 🌍</p>
            </div>
          ),
          duration: 5, // Durée du message en secondes
        });
      }
    }
  }, [keycloak, navigate]);
  return (
    <>
    {
      /*
      <NotificationProvider>
      <NotificationBell></NotificationBell>
      </NotificationProvider>
      */
    }
    {
     // <NotificationTest></NotificationTest>
    }
        <DrawerAppBar />
      
      <Container>

        <Box
        >
          <Experiences />
          <Plats />
          <AddReview />
          <TopHote />
        </Box>
        <TestimonialCarousel />
      </Container>
      <Footer />
    </>
  );
}
