import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { 
  Modal, 
  Form, 
  Button, 
  DatePicker, 
  message, 
  Typography, 
  Empty, 
  Tooltip, 
  Popconfirm,
  Row,
  Col,
  Tag,
  Statistic,
  Card,
  Select
} from 'antd';
import { 
  CalendarOutlined, 
  PlusOutlined, 
  DeleteOutlined, 
  EditOutlined, 
  ClockCircleOutlined,
  ScheduleOutlined,
  StarOutlined,
  UserOutlined,
  EuroCircleOutlined
} from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { ExperienceAvailabilityViewer } from "./ExperienceAvailabilityViewer";
dayjs.locale('fr');

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const { Option } = Select;
const DAYS_OF_WEEK = [
  { key: 'Lundi', label: 'Lundi' },
  { key: 'Mardi', label: 'Mardi' },
  { key: 'Mercredi', label: 'Mercredi' },
  { key: 'Jeudi', label: 'Jeudi' },
  { key: 'Vendredi', label: 'Vendredi' },
  { key: 'Samedi', label: 'Samedi' },
  { key: 'Dimanche', label: 'Dimanche' }
];
const GestionnaireDRecurrences = () => {
  const [experiences, setExperiences] = useState([]);
  const [filteredExperiences, setFilteredExperiences] = useState([]);
  const [currentExperience, setCurrentExperience] = useState(null);
  const [isRecurrenceModalVisible, setIsRecurrenceModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [form] = Form.useForm();
  const { keycloak } = useKeycloak();

  const fetchExperiences = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_SER}experience/hostn`,
        {
          params: {
            f: 0,
            e: 120,
          },
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        }
      );
      setExperiences(response.data);
      setFilteredExperiences(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des expériences:", error);
      message.error("Échec du chargement des expériences");
      setLoading(false);
    }
  }, [keycloak.token]);

  const calculateStatistics = (experiences) => {
    const totalExperiences = experiences.length;
    const totalCapacity = experiences.reduce((acc, exp) => acc + exp.nombreMaxInvite, 0);
    const totalPrice = experiences.reduce((acc, exp) => acc + exp.prixParInvite, 0);
    const averagePrice = totalExperiences > 0 ? totalPrice / totalExperiences : 0;
    const verifiedExperiences = experiences.filter(exp => exp.isVerified).length;

    return {
      totalExperiences,
      totalCapacity,
      averagePrice: averagePrice.toFixed(2),
      verifiedPercentage: ((verifiedExperiences / totalExperiences) * 100).toFixed(2)
    };
  };

  const handleFilterChange = (value) => {
    let filtered = [...experiences];
    
    switch(value) {
      case 'verified':
        filtered = filtered.filter(exp => exp.isVerified);
        break;
      case 'withRecurrences':
        filtered = filtered.filter(exp => exp.recurenceDates && exp.recurenceDates.length > 0);
        break;
      default:
        break;
    }
    
    setFilteredExperiences(filtered);
    setCurrentPage(1);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const getPaginatedExperiences = () => {
    const startIndex = (currentPage - 1) * pageSize;
    return filteredExperiences.slice(startIndex, startIndex + pageSize);
  };

  const handleEditRecurrences = async (values) => {
    if (!currentExperience) return;

    try {
      const recurrences = values.recurenceDates.map(({ dateRange, day }) => ({
        id: null,
        jour: day, // Use selected day directly
        frequency: true,
        dateDebut: dateRange[0].format('YYYY-MM-DD'),
        dateFin: dateRange[1].format('YYYY-MM-DD'), 
        heurDebut: dateRange[0].format('HH:mm:ss'),
        heurFin: dateRange[1].format('HH:mm:ss'),
        experienceCulinaire: {
          id: currentExperience.id,
          titre: currentExperience.titre,
        }
      }));

      const response = await axios.put(
        `${process.env.REACT_APP_API_RECURENCE}experience/${currentExperience.id}/recurrences`,
        recurrences,
        { 
          headers: { 
            Authorization: `Bearer ${keycloak.token}`,
            'Content-Type': 'application/json'
          } 
        }
      );

      message.success('Récurrences mises à jour avec succès');
      fetchExperiences();
      setIsRecurrenceModalVisible(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour des récurrences:", error);
      message.error('Échec de la mise à jour des récurrences');
    }
  };

  const handleOpenRecurrenceModal = (experience) => {
    setCurrentExperience(experience);
    
    const formattedRecurrences = experience.recurenceDates
      ? experience.recurenceDates.map(r => ({
          dateRange: [
            dayjs(`${r.dateDebut}T${r.heurDebut}`), 
            dayjs(`${r.dateFin}T${r.heurFin}`)
          ],
          day: r.jour
        }))
      : [{ dateRange: undefined, day: undefined }];
    
    form.setFieldsValue({ 
      recurenceDates: formattedRecurrences
    });
    
    setIsRecurrenceModalVisible(true);
  };

  useEffect(() => {
    fetchExperiences();
  }, [fetchExperiences]);

  const statistics = calculateStatistics(filteredExperiences);
  const paginatedExperiences = getPaginatedExperiences();

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="max-w-6xl mx-auto">
        

        <Row gutter={[16, 16]} className="mb-4">
          <Col xs={24} sm={12}>
            <Select 
              style={{ width: '100%' }} 
              placeholder="Filtrer les expériences"
              onChange={handleFilterChange}
            >
              <Option value="">Toutes les expériences</Option>
              <Option value="verified">Expériences vérifiées</Option>
              <Option value="withRecurrences">Avec récurrences</Option>
            </Select>
          </Col>
          <Col xs={24} sm={12}>
            <Select 
              style={{ width: '100%' }} 
              value={pageSize}
              placeholder="Nombre d'expériences par page"
              onChange={handlePageSizeChange}
            >
              <Option value={6}>6 expériences</Option>
              <Option value={12}>12 expériences</Option>
              <Option value={24}>24 expériences</Option>
            </Select>
          </Col>
        </Row>

        {loading ? (
          <div className="text-center">
            <Button loading>Chargement des Expériences</Button>
          </div>
        ) : filteredExperiences.length === 0 ? (
          <Empty 
            description="Aucune expérience trouvée" 
            image={Empty.PRESENTED_IMAGE_SIMPLE} 
          />
        ) : (
          <>
            <Row gutter={[16, 16]}>
              {paginatedExperiences.map((exp) => (
                <Col key={exp.id} xs={24} sm={12} lg={8}>
                  <Card
                    hoverable
                    className="transition-all duration-300 hover:shadow-xl"
                    title={
                      <div className="flex justify-between items-center">
                        <div className="flex items-center">
                          <ScheduleOutlined className="text-blue-500 mr-2" />
                          <span>{exp.titre.substring(0,20)}</span>
                        </div>
                        <Tooltip title="Gérer les Récurrences">
                          <Button 
                            icon={<EditOutlined />} 
                            type="primary" 
                            ghost
                            onClick={() => handleOpenRecurrenceModal(exp)}
                          >
                            Gérer
                          </Button>
                        </Tooltip>
                      </div>
                    }
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Statistic 
                          title="Prix par Invité" 
                          value={exp.prixParInvite} 
                          prefix="€" 
                          valueStyle={{ color: '#3f8600' }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic 
                          title="Capacité Max" 
                          value={exp.nombreMaxInvite} 
                          prefix={<UserOutlined />}
                        />
                      </Col>
                      <Col span={24}>
                        <div className="mt-4">
                          <Text strong>Récurrences : </Text>
                          {!exp.recurenceDates || exp.recurenceDates.length === 0 ? (
                            <Tag color="red">Aucune récurrence</Tag>
                          ) : (
                            exp.recurenceDates.map(recurrence => (
                              <Tag 
                                key={recurrence.id} 
                                color="blue" 
                                className="mb-2"
                              >
                                {recurrence.jour} : {recurrence.dateDebut} à {recurrence.heurDebut}
                              </Tag>
                            ))
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="text-center mt-8">
              <Button 
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(prev => prev - 1)}
                className="mr-2"
              >
                Précédent
              </Button>
              <Text strong className="mx-4">
                Page {currentPage} sur {Math.ceil(filteredExperiences.length / pageSize)}
              </Text>
              <Button 
                disabled={currentPage >= Math.ceil(filteredExperiences.length / pageSize)}
                onClick={() => setCurrentPage(prev => prev + 1)}
              >
                Suivant
              </Button>
            </div>
          </>
        )}
          <Modal
      title={
        <div className="flex items-center">
          <ClockCircleOutlined className="mr-2 text-blue-600" />
          Modifier les Récurrences pour {currentExperience?.titre}
        </div>
      }
      open={isRecurrenceModalVisible}
      onCancel={() => setIsRecurrenceModalVisible(false)}
      onOk={() => form.submit()}
      okText="Enregistrer les Récurrences"
      width={600}
    >
      <Form
        form={form}
        onFinish={handleEditRecurrences}
        layout="vertical"
      >
        <Form.List name="recurenceDates">
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ key, name, ...restField }) => (
                <div 
                  key={key} 
                  className="mb-4 p-4 bg-gray-50 rounded-lg"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'day']}
                    label="Jour de la Semaine"
                    rules={[{
                      required: true,
                      message: 'Veuillez sélectionner un jour'
                    }]}
                    className="w-full mb-4"
                  >
                    <Select 
                      placeholder="Sélectionnez un jour"
                      style={{ width: '100%' }}
                    >
                      {DAYS_OF_WEEK.map(day => (
                        <Option key={day.key} value={day.key}>
                          {day.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, 'dateRange']}
                    label="Plage de Dates"
                    rules={[{
                      required: true,
                      message: 'Veuillez sélectionner une plage de dates'
                    }]}
                    className="w-full"
                  >
                    <RangePicker
                      showTime={{
                        format: 'HH:mm:ss',
                        minuteStep: 5
                      }}
                      className="w-full"
                      format="YYYY-MM-DD HH:mm:ss"
                      placeholder={['Date/Heure de Début', 'Date/Heure de Fin']}
                    />
                  </Form.Item>
                  
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => remove(name)}
                    danger
                    type="dashed"
                    block
                  >
                    Supprimer la Récurrence
                  </Button>
                </div>
              ))}
              <Button
                type="primary"
                onClick={() => add()}
                icon={<PlusOutlined />}
                block
                className="mt-4"
              >
                Ajouter une Nouvelle Récurrence
              </Button>
            </div>
          )}
        </Form.List>
      </Form>
    </Modal>

      </div>
      <ExperienceAvailabilityViewer 
  experiences={experiences}
  onSelectTimeSlot={(slot) => {
    // Handle slot selection
    console.log('Selected slot:', slot);
  }}
/>
    </div>
  );
};

export default GestionnaireDRecurrences;