import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, LinearProgress, Rating } from "@mui/material";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { useParams } from "react-router-dom";

const RatingInfos = ({e}) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    width: "130px",
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#d7a022" : "#d7a022",
    },
  })); 
  console.log("i m in rating info ",e);
  const [averageRatings, setAverageRatings] = useState({
    cuisineRating: e?.cuisineRating ?? 0,
    ambianceRating: e?.ambianceRating ?? 0,
    valueRating: e?.valueRating ?? 0,
    cleanlinessRating: e?.cleanlinessRating ?? 0,
    overallAverage: e?.overallAverage ?? 0,
    totalReviews: e?.nombreAvis ?? 0,
});

  {
  /*useEffect(() => {
    fetch(`http://localhost:8080/review/averageRatings/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setAverageRatings(data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the average ratings!",
          error
        );
      });
  }, [id]);*/
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        padding: 2,
      }}
    >
      {/* Box with overall rating for mobile */}
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          alignItems: "flex-start", // Align items to the start (left) in mobile view
          width: "100%",
          padding: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            alignSelf: "flex-start",
            fontWeight: "bold",
            marginBottom: "8%",
            textAlign: "left", // Align text to the left
          }}
        >
          Avis
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "auto",
            bgcolor: "#d7a022",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "20px",
            padding: 2,
            marginBottom: "20px", // Add spacing between this box and the labels
          }}
        >
          <Typography
            sx={{ color: "white", fontSize: "30px", fontWeight: "bold" }}
          >
            {Math.min((e.rating).toFixed(1),5)}
          </Typography>
          <Typography
            sx={{ color: "white", fontSize: "12px", fontWeight: "bold" }}
          >
            sur 5
          </Typography>
          <Typography
            sx={{ color: "white", fontSize: "12px", fontWeight: "bold" }}
          >
            ({averageRatings.totalReviews} avis)
          </Typography>
          <Rating
            name="disabled"
            value={5}
            disabled
            sx={{ color: "#efff8b " }}
          />
        </Box>
        {[
          { label: "Rapport Qualité/Prix", value: e.valueRating? e.valueRating/e.nombreAvis: 0 },
          { label: "Cuisine", value: e.cuisineRating? e.cuisineRating/e.nombreAvis: 0 },
          { label: "Ambiance", value: e.ambianceRating?e.ambianceRating/e.nombreAvis: 0 },
          { label: "Propreté", value: e.cleanlinessRating?e.cleanlinessRating/e.nombreAvis :0 },
        ].map((rating, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: 1,
              justifyContent: "center",
              textAlign: "left",
              marginTop: "10px", // Add spacing between progress bars on mobile
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                flex: 1,
                textAlign: "left",
              }}
            >
              {rating.label}
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={(rating.value * 100) / 5}
            />
            <Typography
              variant="body2"
              sx={{ marginLeft: "10px", fontWeight: "bold" }}
            >
              {rating.value.toFixed(1)}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Ratings details for larger screens */}
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            alignSelf: "flex-start",
            fontWeight: "bold",
            marginBottom: "8%",
            marginLeft: { xs: 0, md: "30px" },
          }}
        >
          Avis
        </Typography>
        {[
          { label: "Rapport Qualité/Prix", value: e.valueRating? e.valueRating/e.nombreAvis: 0 },
          { label: "Cuisine", value: e.cuisineRating? e.cuisineRating/e.nombreAvis: 0 },
          { label: "Ambiance", value: e.ambianceRating?e.ambianceRating/e.nombreAvis: 0 },
          { label: "Propreté", value: e.cleanlinessRating?e.cleanlinessRating/e.nombreAvis :0 },
        ].map((rating, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: 1,
              justifyContent: "center",
              textAlign: "left",
              marginLeft: { xs: 0, md: "-80px" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                flex: 1,
                textAlign: "left",
                marginLeft: { xs: 0, md: "70px" },
              }}
            >
              {rating.label}
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={(rating.value * 100) / 5}
            />
            <Typography
              variant="body2"
              sx={{ marginLeft: "10px", fontWeight: "bold" }}
            >
              {rating.value.toFixed(1)}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Box with overall rating for larger screens */}
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          width: 182,
          height: 154,
          bgcolor: "#d7a022",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "20px",
          padding: 2,
          marginTop: { md: "60px" },
        }}
      >
        <Typography
          sx={{ color: "white", fontSize: "35px", fontWeight: "bold" }}
        >
          {Math.min((e.rating).toFixed(1),5)}
        </Typography>
        <Typography
          sx={{ color: "white", fontSize: "15px", fontWeight: "bold" }}
        >
          sur 5
        </Typography>
        <Typography
          sx={{ color: "white", fontSize: "15px", fontWeight: "bold" }}
        >
          ({averageRatings.totalReviews} avis)
        </Typography>
        <Rating name="disabled" value={5} disabled sx={{ color: "#efff8b " }} />
      </Box>
    </Box>
  );
};

export default RatingInfos;