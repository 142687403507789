import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  Button as MuiButton,
} from "@mui/material";
import { Select as MuiSelect, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import { Select, Row, Col, Button } from "antd";
import ReservationPopup from "./Popup";
import ReservationCardMobile from "./ReservationCardMobile";
import keycloak from "../../keycloak";

// Styles personnalisés
const CustomSelect = styled(MuiSelect)(({ theme }) => ({
  backgroundColor: "#ffffff",
  width: "100%",
  borderRadius: "8px",
  marginBottom: "30px",
  "& .MuiSelect-select": {
    padding: "15px",
    "&.Mui-focused": {
      outline: `2px solid #d7a022`,
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    border: `1px solid #d7a022`,
    "&:hover": {
      border: "2px solid #d7a022",
      backgroundColor: "white",
    },
    "&.Mui-focused": {
      border: `2px solid #d7a022`,
    },
    "& fieldset": {
      border: "none",
    },
  },
}));

const DateButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ffffff",
  border: "1px solid transparent",
  "&:hover": {
    border: "2px solid #d7a022",
    backgroundColor: "white",
  },
}));

const ReservationCard = ({ e }) => {
  const [open, setOpen] = useState(false);
  const [selectedGuests, setSelectedGuests] = useState(2);
  const [selectedRecurrenceDate, setSelectedRecurrenceDate] = useState(null);
  const [optionDates, setOptionDates] = useState([]); // Initialize as empty array
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    if (!keycloak.authenticated || !keycloak.hasRealmRole("Client")) {
      alert("Vous devez être connecté en tant que client pour réserver");
      keycloak.login();
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Filter dates to include only those after today
  const filterFutureDates = (dates) => {
    const currentDate = new Date(); // Today's date
    return dates.filter((date) => new Date(date) > currentDate); // Keep only future dates
  };

  useEffect(() => {
    if (e && e.temps && e.recurenceDates) {
      try {
        // Parse the starting date
        const startDate = new Date(e.temps).getTime();

        // Find the maximum date from recurenceDates
        const endDate = Math.max(
          ...e.recurenceDates.map((d) => new Date(d.dateFin).getTime())
        );

        // Generate all dates from startDate to endDate
        const dates = [];
        for (let i = startDate; i <= endDate; i += 24 * 60 * 60 * 1000) {
          dates.push(new Date(i).toISOString().split("T")[0]);
        }

        // Filter dates to include only future dates
        const futureDates = filterFutureDates(dates);

        console.log("Generated future dates:", futureDates); // Debugging output
        setOptionDates(futureDates);
      } catch (error) {
        console.error("Error generating dates:", error);
      }
    }
  }, [e]);

  const handleRecurrenceDateChange = (value) => {
    setSelectedRecurrenceDate(value); // value is the selected id
  };

  const getDayName = (dateString) => {
    const days = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"];
    const date = new Date(dateString);
    return days[date.getDay()];
  };

  if (isMobile) {
    return <ReservationCardMobile e={e} onClick={handleClickOpen} />;
  }

  return (
    <Box
      sx={{
        width: "508px",
        borderRadius: "20px",
        boxShadow: 3,
        marginLeft: "33px",
        marginRight: "300px",
        p: 3,
        "& .MuiFormControl-root": {
          width: "100%",
          mb: 2,
        },
      }}
    >
      {/* Rest of the component remains the same */}
      <Box
        sx={{
          bgcolor: "#F5F5F5",
          width: "437px",
          borderRadius: "20px",
          padding: "35px",
          gap: "10px",
          marginLeft: "10px",
          textAlign: "left",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "16px",
            marginBottom: "10px",
            fontWeight: "600",
          }}
        >
          Nombre d'invités
        </Typography>
        <MuiSelect
          value={selectedGuests}
          onChange={(e) => setSelectedGuests(e.target.value)}
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            borderRadius: "8px",
            marginBottom: "20px",
          }}
        >
          {Array.from({ length: e.nombreMaxInvite }, (_, i) => (
            <MenuItem key={i + 1} value={i + 1}>
              {i + 1} invité{i + 1 > 1 ? 's' : ''}
            </MenuItem>
          ))}
        </MuiSelect>

        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "16px",
            marginBottom: "10px",
            fontWeight: "600",
          }}
        >
          Date de réservation
        </Typography>

        <div>
          <Row gutter={[8, 8]} style={{ marginBottom: '16px' }}>
            {optionDates.slice(0, 4).map((option) => (
              <Col key={option}>
                <Button
                  type={selectedRecurrenceDate === option ? 'primary' : 'default'}
                  onClick={() => handleRecurrenceDateChange(option)}
                  style={{
                    borderRadius: '8px',
                    padding: '8px 16px',
                    font: "Poppins",
                    fontWeight: selectedRecurrenceDate === option ? 'bold' : 'normal',
                    backgroundColor: selectedRecurrenceDate === option ? '#d7a022' : 'white',
                    height: "60px",
                  }}
                >
                  {new Date(option).toLocaleDateString('fr-FR', {
                    day: '2-digit',
                    month: 'short',
                  })}
                </Button>
              </Col>
            ))}
          </Row>
          <Select
            showSearch
            style={{ width: '100%', height: '60px', borderRadius: '8px' }}
            placeholder="Sélectionnez une date de réservation"
            value={selectedRecurrenceDate}
            onChange={handleRecurrenceDateChange}
            optionFilterProp="label"
            filterOption={(input, option) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
            options={optionDates.map((option) => ({
              value: option,
              label: `${getDayName(option)} ${new Date(option).toLocaleDateString('fr-FR')} - 13h00`,
            }))}
          />
        </div>

        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            fontFamily: "Poppins",
            color: "black",
            fontSize: "16px",
          }}
        >
          Annulation sans frais jusqu'à 1 jour avant la date de réservation.
        </Typography>
      </Box>

      {selectedRecurrenceDate ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <MuiButton
            variant="contained"
            sx={{
              backgroundColor: "#d7a022",
              width: "380px",
              padding: "10px",
              borderRadius: "10px",
              fontFamily: "Poppins",
              textTransform: "none",
              fontSize: "18px",
              "&:hover": { backgroundColor: "#d7a022" },
            }}
            onClick={handleClickOpen}
          >
            Réserver
          </MuiButton>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}></Box>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: "none",
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogContent>
          {keycloak.hasRealmRole("Client") && (
            <ReservationPopup
              id={e.id}
              prix={e.prixParInvite}
              setOpen={setOpen}
              guestCount={selectedGuests}
              inforeservation={selectedRecurrenceDate}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ReservationCard;