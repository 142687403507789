import React, { useState, useEffect } from 'react';
import { Calendar, Badge, Tooltip, Card, Button, Modal, Form, DatePicker, Select, message } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, StopOutlined, CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';

const { RangePicker } = DatePicker;
const { Option } = Select;

const CombinedCalendar = ({ availabilities }) => {
  const [experiences, setExperiences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    const fetchExperiences = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SER}experience/hostn`, // Base API URL + endpoint
          {
            params: {
              f: 0, // Start index
              e: 120, // End index
            },
            headers: {
              Authorization: `Bearer ${keycloak.token}`, // Include Bearer token if needed
            },
          }
        );

        setExperiences(response.data);
        setLoading(false);
      } catch (error) {
        message.error('Error loading experiences');
        console.error('Error fetching experiences:', error);
        setLoading(false);
      }
    };

    fetchExperiences();
  }, []);

  const getRecurringDates = (recurenceDates) => {
    const allDates = [];
    
    recurenceDates.forEach(recurrence => {
      const startDate = moment(recurrence.dateDebut);
      const endDate = moment(recurrence.dateFin);
      const day = recurrence.jour.toLowerCase();
      
      let currentDate = startDate.clone();
      while (currentDate.isSameOrBefore(endDate)) {
        if (currentDate.format('dddd').toLowerCase() === day) {
          allDates.push({
            date: currentDate.format('YYYY-MM-DD'),
            heureDebut: recurrence.heurDebut,
            heureFin: recurrence.heurFin
          });
        }
        currentDate.add(1, 'days');
      }
    });
    
    return allDates;
  };

  const getAllEventDates = (date) => {
    const events = [];
    const formattedDate = date.format('YYYY-MM-DD');

    // Check availabilities
    availabilities?.forEach(avail => {
      if (moment(formattedDate).isBetween(avail.dateDebut, avail.dateFin, null, '[]')) {
        events.push({
          type: 'availability',
          status: avail.type,
          content: `Disponibilité: ${avail.type}`
        });
      }
    });

    // Check experiences and their recurrences
    experiences?.forEach(exp => {
      const recurringDates = getRecurringDates(exp.recurenceDates);
      const matchingDate = recurringDates.find(d => d.date === formattedDate);
      
      if (matchingDate) {
        events.push({
          type: 'experience',
          status: 'EXPERIENCE',
          content: `${exp.titre} (${exp.heurDebut}-${exp.heurFin})`,
          title: exp.titre
        });
      }
    });

    return events;
  };

  const dateCellRender = (value) => {
    const events = getAllEventDates(value);
    
    return (
      <ul className="events" style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {events.map((event, index) => {
          let icon, color, text;
          
          switch (event.type) {
            case 'availability':
              switch (event.status) {
                case 'BLOQUE':
                  icon = <StopOutlined />;
                  color = '#ff4d4f';
                  text = 'Bloqué';
                  break;
                case 'JOURNEE_PARTIELLE':
                  icon = <ClockCircleOutlined />;
                  color = '#faad14';
                  text = 'Partiel';
                  break;
                default:
                  icon = <CheckCircleOutlined />;
                  color = '#52c41a';
                  text = 'Disponible';
              }
              break;
            case 'experience':
              icon = <CalendarOutlined />;
              color = '#1890ff';
              text = event.title;
              break;
            default:
              icon = <CheckCircleOutlined />;
              color = '#52c41a';
              text = '';
          }

          return (
            <li key={index} style={{ marginBottom: 3 }}>
              <Tooltip title={event.content}>
                <Badge
                  status="processing"
                  color={color}
                  text={
                    <span style={{ color: color }}>
                      {icon} {text}
                    </span>
                  }
                />
              </Tooltip>
            </li>
          );
        })}
      </ul>
    );
  };

  // Save new availability
  const handleSave = async (values) => {
    const newStart = values.dateRange[0].format('YYYY-MM-DD');
    const newEnd = values.dateRange[1].format('YYYY-MM-DD');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_SER}disponibility/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        body: JSON.stringify({
          dateDebut: newStart,
          dateFin: newEnd,
          type: values.type
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save availability');
      }

      message.success('Availability added successfully');
      setIsModalVisible(false);
    } catch (error) {
      message.error('Error saving availability');
      console.error('Error:', error);
    }
  };

  return (
    <Card 
      title="Calendrier des disponibilités" 
      extra={
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          Ajouter une Disponibilité
        </Button>
      }
    >
      <Calendar 
        dateCellRender={dateCellRender}
        loading={loading}
        locale="fr"
      />

      <Modal
        title="Ajouter une Disponibilité"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form onFinish={handleSave}>
          <Form.Item
            name="dateRange"
            label="Plage de Dates"
            rules={[{ required: true, message: 'Veuillez sélectionner une plage de dates' }]}
          >
            <RangePicker style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="type"
            label="Type de Disponibilité"
            rules={[{ required: true, message: 'Veuillez sélectionner un type' }]}
          >
            <Select>
              <Option value="DISPONIBLE">Disponible</Option>
              <Option value="JOURNEE_PARTIELLE">Partielle</Option>
              <Option value="BLOQUE">Bloqué</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default CombinedCalendar;