import React, { useState } from 'react';
import axios from 'axios';
import { Container, Typography, Button, Card, CardContent, Box, Avatar } from '@mui/material';
import { useKeycloak } from "@react-keycloak/web";

const PhotoUpload = () => {
    const { keycloak } = useKeycloak();
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);

        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        reader.readAsDataURL(selectedFile);
    };

    const handleUpload = async () => {
        if (!file) {
            alert("Veuillez choisir une image avant de la télécharger.");
            return;
        }

        if (!keycloak.authenticated) {
            alert("Utilisateur non authentifié");
            return;
        }

        const email = keycloak.tokenParsed.email;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('email', email);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_SER}api/user/upload-Avatar`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${keycloak.token}`
                },
            });
            alert('Avatar uploaded successfully');
        } catch (error) {
            console.error('Error uploading avatar', error);
            alert('Erreur lors du téléchargement de l\'avatar');
        }
    };

    return (
        <Container maxWidth="sm">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Upload Avatar
                </Typography>

                <Box my={2}>
                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                    >
                        Choose Avatar
                        <input
                            type="file"
                            hidden
                            onChange={handleFileChange}
                        />
                    </Button>
                </Box>

               
                {preview && (
                    <Box my={2} display="flex" justifyContent="center">
                        <Avatar
                            alt="Avatar Preview"
                            src={preview}
                            sx={{ width: 140, height: 140 }}
                        />
                    </Box>
                )}

                <Box my={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                        fullWidth
                    >
                        Upload Avatar
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default PhotoUpload;
