import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Badge, Rate, Progress, Image, Space, Tag, Typography, Tooltip } from 'antd';
import { FireOutlined, UserOutlined, ClockCircleOutlined, ShopOutlined, StarOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
const { Title, Text } = Typography;

const PopularExperiences = () => {
  const [experiences, setExperiences] = useState([]);
  const [loading, setLoading] = useState(true);
  const {keycloak}=useKeycloak();
  useEffect(() => {
    fetchExperiences();
  }, []);

  const fetchExperiences = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_SER}experience/hostn`, // Base API URL + endpoint
            {
              params: {
                f: 0, // Start index
                e: 120, // End index
              },
              headers: {
                Authorization: `Bearer ${keycloak.token}`, // Include Bearer token if needed
              },
            }
          );
      setExperiences(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching experiences:", error);
      setLoading(false);
    }
  };

  const getHighDemandIndicator = (nombreInvites, nombreMaxInvite) => {
    const demandPercentage = (nombreInvites / nombreMaxInvite) * 100;
    return (
      <Tooltip title={`${demandPercentage.toFixed(1)}% Occupancy`}>
        <Progress
          type="circle"
          percent={demandPercentage}
          width={40}
          format={(percent) => (
            <FireOutlined style={{ color: percent > 75 ? '#ff4d4f' : '#faad14' }} />
          )}
        />
      </Tooltip>
    );
  };

  return (
    <div className="p-4">
      <Card loading={loading}>
        <Title level={4} className="mb-4">
          <FireOutlined className="mr-2 text-red-500" />
          Popular Experiences
        </Title>

        <Row gutter={[24, 24]}>
          {experiences.map((exp) => (
            <Col xs={24} sm={12} lg={8} key={exp.id}>
              <Card
                hoverable
                cover={
                  <div className="relative h-48">
                    <Image.PreviewGroup>
                      <Row gutter={8}>
                        {exp.photos.slice(0, 2).map((photo) => (
                          <Col span={12} key={photo.id}>
                            <Image
                              src={`${process.env.REACT_APP_API_SER}photos/${photo.id}`}
                              alt={exp.titre}
                              className="object-cover h-48 w-full rounded-t"
                            />
                          </Col>
                        ))}
                      </Row>
                    </Image.PreviewGroup>
                    <Badge.Ribbon
                      text={exp.typeCuisine}
                      color="blue"
                      className="absolute top-2 right-2"
                    />
                  </div>
                }
                actions={[
                  <Statistic
                    title="Price/Person"
                    value={exp.prixParInvite}
                    prefix="€"
                    valueStyle={{ fontSize: '16px' }}
                  />,
                  <Space direction="vertical" size={0}>
                    <Text>Capacity</Text>
                    <Text strong>{`${exp.nombreInvites}/${exp.nombreMaxInvite}`}</Text>
                  </Space>,
                  getHighDemandIndicator(exp.nombreInvites, exp.nombreMaxInvite)
                ]}
              >
                <Card.Meta
                  title={
                    <Space>
                      {exp.titre}
                      {exp.rating >= 4.5 && (
                        <Tag color="gold" icon={<StarOutlined />}>
                          Top Rated
                        </Tag>
                      )}
                    </Space>
                  }
                  description={
                    <Space direction="vertical" size={2}>
                      <Space>
                        <UserOutlined />
                        <Text>{exp.host.nom}</Text>
                      </Space>
                      <Space>
                        <ClockCircleOutlined />
                        <Text>{`${exp.heurDebut} - ${exp.heurFin}`}</Text>
                      </Space>
                      <Space>
                        <ShopOutlined />
                        <Text>{exp.lieu}</Text>
                      </Space>
                      <Rate disabled defaultValue={exp.rating} allowHalf />
                      
                      <Row gutter={8} className="mt-2">
                        <Col span={12}>
                          <Tooltip title="Cuisine Rating">
                            <Progress 
                              percent={(exp.cuisineRating/15)*100}
                              size="small"
                              format={percent => `${(percent/20).toFixed(1)}`}
                            />
                          </Tooltip>
                        </Col>
                        <Col span={12}>
                          <Tooltip title="Ambiance Rating">
                            <Progress 
                              percent={(exp.ambianceRating/15)*100}
                              size="small"
                              format={percent => `${(percent/20).toFixed(1)}`}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Space>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
    </div>
  );
};

export default PopularExperiences;