import React, { useState, useEffect } from 'react';
import { 
  Row, 
  Col, 
  Modal, 
  DatePicker, 
  Form, 
  Select, 
  TimePicker, 
  Input,
  Tabs,  // This was already in your original imports
  Typography
} from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;

export default function AddRecurence({
  title = "Ajouter une récurrence",
  visible,
  onOk,
  onCancel,
  e
}) {
  const [form] = Form.useForm();
  const [activeDays, setActiveDays] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [daySettings, setDaySettings] = useState({
    "Lundi": { frequency: null, heurDebut: null, heurFin: null ,jour: "lundi"},
    "Mardi": { frequency: null, heurDebut: null, heurFin: null ,jour: "mardi"},
    "Mercredi": { frequency: null, heurDebut: null, heurFin: null ,jour: "mercredi"},
    "Jeudi": { frequency: null, heurDebut: null, heurFin: null ,jour: "jeudi"},
    "Vendredi": { frequency: null, heurDebut: null, heurFin: null , jour: "vendredi"},
    "Samedi": { frequency: null, heurDebut: null, heurFin: null , jour: "samedi"},
    "Dimanche": { frequency: null, heurDebut: null, heurFin: null , jour: "dimanche"},
  });

  useEffect(() => {
    if (e && e.length > 0) {
      const updatedSettings = { ...daySettings };
      const activeInputDays = [];

      e.forEach((recurrence) => {
        if (updatedSettings[recurrence.jour]) {
          updatedSettings[recurrence.jour] = {
            frequency: recurrence.frequency,
            heurDebut: recurrence.heurDebut,
            heurFin: recurrence.heurFin,
            jour: recurrence.jour
          };
          activeInputDays.push(recurrence.jour);
        }
      });

      setDaySettings(updatedSettings);
      setActiveDays(activeInputDays);
      
      // Set initial date range if available in existing data
      if (e[0]?.dateDebut && e[0]?.dateFin) {
        setDateRange([moment(e[0].dateDebut), moment(e[0].dateFin)]);
      }
    }
  }, [e]);

  const handleRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleActiveDaysChange = (checkedValues) => {
    setActiveDays(checkedValues);
  };
  const handleTimeChange = (day, key, time) => {
    console.log(`Changing ${day} ${key}:`, time ? time.format('HH:mm:ss') : null);
    
    setDaySettings((prevSettings) => {
      const newSettings = {
        ...prevSettings,
        [day]: {
          ...prevSettings[day],
          [key]: time ? time.format('HH:mm:ss') : null,
        },
      };
      
      console.log('Updated daySettings:', newSettings);
      return newSettings;
    });
  };

  const handleOk = () => {
    const filteredSettings = Object.keys(daySettings)
      .filter((day) => activeDays.includes(day))
      .map((day) => ({
        jour: day,
        heurDebut: daySettings[day].heurDebut,
        heurFin: daySettings[day].heurFin,
        // ... other properties
        jour: day,
        frequency: true,
        dateDebut: dateRange[0]?.toISOString() || null,
        dateFin: dateRange[1]?.toISOString() || null,
      }));

    console.log('Filtered Settings:', filteredSettings);
    
    if (onOk) {
      onOk(filteredSettings);
    }
  };

  return (
    <Modal
      title={
        <Typography.Title level={4} style={{ margin: 0, textAlign: "center" }}>
          {title}
        </Typography.Title>
      }
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      centered
      okText="Enregistrer"
      cancelText="Annuler"
      style={{
        borderRadius: "12px",
        overflow: "hidden",
        maxWidth: "800px",
      }}
      bodyStyle={{
        padding: "24px",
        background: "#f9f9f9",
        borderRadius: "12px",
      }}
    >
      <Form form={form}>
        <Form.Item
          name="recurringDates"
          label="Sélectionner les dates"
          rules={[{ required: true, message: 'Veuillez sélectionner des dates' }]}
        >
          <RangePicker 
            value={dateRange} 
            className="w-full" 
            onChange={handleRangeChange} 
          />
        </Form.Item>

        <Form.Item
          name="recurringDays"
          label="Jours récurrents"
          rules={[{ required: true, message: 'Veuillez sélectionner des jours' }]}
        >
          <Select 
            mode="multiple" 
            value={activeDays}
            placeholder="Sélectionner les jours" 
            onChange={handleActiveDaysChange}
          >
            {Object.keys(daySettings).map((day) => (
              <Option key={day} value={day}>{day}</Option>
            ))}
          </Select>
        </Form.Item>

        {activeDays.map((day) => (
          <div key={day} className="mb-4">
            <Typography.Text strong>{day}</Typography.Text>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Heure de début">
                  <TimePicker
                    format="HH:mm"
                    value={daySettings[day].heurDebut ? moment(daySettings[day].heurDebut, "HH:mm") : null}
                    className="w-full"
                    onChange={(time) => handleTimeChange(day, "heurDebut", time)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Heure de fin">
                  <TimePicker
                    format="HH:mm"
                    value={daySettings[day].heurFin ? moment(daySettings[day].heurFin, "HH:mm") : null}
                    className="w-full"
                    onChange={(time) => handleTimeChange(day, "heurFin", time)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        ))}
      </Form>
    </Modal>
  );
}