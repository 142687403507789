import React, { useEffect, useState } from "react";
import logoDark from "../img/Group 2040.webp";
import logoBright from "../img/Group.webp";
import messageIcon from "../img/Icon.webp";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import NotificationBell from "../components/NotificationSys/NotificationBell";
import { NotificationProvider } from "../components/NotificationSys/NotificationContext";

function NavHote({ avatar, transparent }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [avatarImage, setAvatarImage] = useState("Profile");
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SER}host/profile/getProfile`,
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
            },
          }
        );
        setAvatarImage(
          `${process.env.REACT_APP_API_SER}photos/${response.data.avatar}`
        );
        localStorage.setItem("avatar", avatarImage);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, [keycloak.token]);

  const handleLogout = () => {
    if (window.confirm("Est-ce que vous êtes sûr de vouloir vous déconnecter ?")) {
      keycloak.logout();
    }
  };

  return (
    <NotificationProvider>
      <nav
        className={`flex items-center justify-between p-4 md:px-20 md:py-9 absolute top-0 w-full z-20 ${
          transparent ? "bg-transparent" : "bg-white"
        }`}
      >
        {/* Logo */}
        <div className="flex items-center">
          <img
            src={transparent ? logoBright : logoDark}
            alt="Feast and Food Logo"
            className="h-12 md:h-14 lg:h-16"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
        </div>

        {/* Buttons and Profile Picture for larger screens */}
        <div
          className={`hidden md:flex items-center space-x-4 lg:space-x-20 ${
            transparent ? "text-white" : "text-grey-text"
          }`}
        >
          {keycloak.hasRealmRole("Host") && (
            <button
              className="poppins-medium text-base md:text-lg"
              onClick={() => navigate("/tableau-de-bord")}
            >
              Tableau de bord
            </button>
          )}
          {keycloak.hasRealmRole("Host") && (
            <button
              className="poppins-medium text-base md:text-lg"
              onClick={() => navigate("/mes-annonces")}
            >
              Mes annonces
            </button>
          )}
          <button
            className="poppins-medium text-base md:text-lg"
            onClick={() => navigate("/reservations")}
          >
            Réservations
          </button>
          {keycloak.hasRealmRole("Host") && (
            <button className="poppins-medium text-base md:text-lg">
              Banque d’images
            </button>
          )}
          <button onClick={() => navigate("/HostProfile")}>
            <img
              src={avatarImage}
              alt="Profile"
              className="h-8 w-8 md:w-16 md:h-16 rounded-full"
            />
          </button>
        </div>

        {/* Hamburger Icon for smaller screens */}
        <button
          data-collapse-toggle="navbar-menu"
          type="button"
          className={`inline-flex items-center p-2 w-10 h-10 justify-center text-sm ${
            transparent ? "text-white" : "text-gray-500"
          } rounded-lg md:hidden`}
          aria-controls="navbar-menu"
          aria-expanded={isMenuOpen}
          onClick={toggleMenu}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        {/* Dropdown Menu */}
        {isMenuOpen && (
          <div className="absolute top-full left-0 w-full bg-white shadow-md md:hidden">
            <div className="flex flex-col items-center space-y-2 py-4">
              <button
                className="text-grey-text poppins-medium text-base"
                onClick={() => navigate("/tableau-de-bord")}
              >
                Tableau de bord
              </button>
              {keycloak.hasRealmRole("Host") && (
                <button
                  className="text-grey-text poppins-medium text-base"
                  onClick={() => navigate("/mes-annonces")}
                >
                  Mes annonces
                </button>
              )}
              <button
                className="text-grey-text poppins-medium text-base"
                onClick={() => navigate("/reservations")}
              >
                Réservations
              </button>
              <button
                className="text-grey-text poppins-medium text-base"
                onClick={() => navigate("/HostProfile")}
              >
                Banque d’images
              </button>
            </div>
          </div>
        )}

        {/* Icon Buttons */}
        <div className="hidden md:flex items-center md:space-x-5">
          <button className={transparent ? "text-white" : "text-gray-500"}>
            <img
              src={messageIcon}
              alt="Message Icon"
              className="h-4 w-6 sm:h-5 sm:w-7 md:h-6 md:w-8"
            />
          </button>
          {/* Replace the old notification icon with the new NotificationBell component */}
          <NotificationBell transparent={transparent} />
          <button onClick={handleLogout} className="text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="red"
              className="h-4 w-6 sm:h-5 sm:w-7 md:h-6 md:w-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h6a2 2 0 012 2v1"
              />
            </svg>
          </button>
        </div>
      </nav>
    </NotificationProvider>
  );
}

export default NavHote;